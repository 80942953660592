import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-yandex-mail-login-form',
  templateUrl: './yandex-mail-login-form.component.html',
  styleUrls: ['./yandex-mail-login-form.component.css'],
})
export class YandexMailLoginFormComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
